import {graphql, useStaticQuery} from "gatsby";
import Img from "gatsby-image";
import React from "react";
import {H2, H3, P} from "../components/typography";
import Layout from "../components/layout";


export default function TermsPage() {
    const { logo } = useStaticQuery(graphql`
        query TermsPageQuery {
          logo: file(relativePath: {eq: "logo.png"}) {
             image: childImageSharp {
              fluid(maxWidth: 512) {
                ...GatsbyImageSharpFluid_withWebp
                ...GatsbyImageSharpFluidLimitPresentationSize
              }
            }
          }
        }
      `);
    return (
        <Layout>
            <div className="w-full max-w-6xl mx-auto py-16 px-2">
                <Img fluid={logo.image.fluid} className="mx-auto mb-8"/>
                <H2 className="uppercase text-center mb-4">Service Agreement &amp; Terms of Use</H2>
                <P className="mb-8">
                    {
                        `We would like to welcome you to our website, quickcomp.io, which is owned and operated by MASTERCOMP APPRAISAL SOLUTIONS LTD. Throughout this document, we refer to MASTERCOMP APPRAISAL SOLUTIONS LTD., its directors, officers, employees, agents and its parent, subsidiaries and corporate affiliates as “QuickComp”, “we”, “our” or “us”. Through our websites and associated applications and software (collectively, the “Platform”), we provide an online service that enables clients to store, aggregate, analyze and interpret property assessment information through quickcomp.io and its related mobile properties and applications (collectively, the “Website”). The Website and the paid services available thereon are provided to assist clients in such forms of data aggregation, analysis, interpretation and management and related materials and services (collectively, the "Platform"), and for no other purpose.`
                    }
                </P>

                <section className="mb-8">
                    <H3 className="uppercase mb-4">
                        1. ACCEPTANCE OF TERMS AND GOVERNING JURISDICTION
                    </H3>
                    <P>{
                        `This document and the terms and conditions herein apply to the Website, the Platform and any other services we may provide to you, either directly or indirectly. Use of the Website  and the Platform constitutes your agreement, without limitation, to this Service Agreement & Terms of Use (the “TOU”) and agreement to hold QuickComp harmless from and against all claims, expenses, damages, costs or any loss of any kind arising out of your use of the Website and the Platform. All services provided through the Platform are deemed to have been provided in the Alberta and are governed by the laws of the Province of Alberta, and the federal laws of Canada as may be applicable, without regard to conflict of laws principals. You hereby attorn to the exclusive jurisdiction of the Courts of the Province of Alberta. `
                    }</P>
                    <P>
                        {`The Platform and any services that QuickComp provides to any person who utilizes the Website (a “User” or “you”) are subject to the following TOU. QuickComp reserves the right to update the TOU at any time pursuant to Section 3, below. The most current version of the TOU can be reviewed by clicking on the "Terms of Use" hypertext link located at the bottom of the Website, or by clicking `}
                        <a href="https://quickcim.io/terms" className="text-blue">here</a>.
                    </P>
                    <P className="pl-8">
                        {`A. The TOU, which incorporate by reference other provisions applicable to use of the Platform, including, but not limited to, the Privacy Policy, and all supplemental terms and conditions set forth herein governing the use of certain specific material contained in the Platform, sets forth the terms and conditions that apply to use of the Platform by User. By using the Platform (other than to read the TOU for the first time), User agrees to comply with all of the terms and conditions hereof, including without limitation all User representations and warranties set out in the TOU as may be amended from time to time. The right to use the Platform is personal to User and is not transferable to any other person or entity. User is responsible for all use of any account registered on the Platform by User under any screen name or password (“User's Account”) and for ensuring that all use of User's Account complies fully with the provisions of the TOU. User shall be responsible for protecting the confidentiality of User's password(s).`}
                    </P>
                    <P className="pl-8">
                        {`B. QuickComp shall have the right at any time to change or discontinue any aspect or feature of the Website, including, but not limited to, content, product or service offering, and software needed for access or use.`}
                    </P>
                    <P className="font-semibold">
                        {`Any User who does not agree to be bound by the terms of the TOU as may be amended from time to time shall not use or access the Platform or any materials produced therefrom.`}
                    </P>
                </section>

                <section className="mb-8">
                    <H3 className="uppercase mb-4">
                        2. PRIVACY
                    </H3>
                    <P>
                        You can view our Privacy Policy <a href="https://quickcomp.io/privacy" className="text-blue">here</a>. {`We encourage you to review the contents of this document, as it governs your rights and our responsibilities in relation to the different types of information a User may disclose to us through the Platform or through other means.`}
                    </P>
                </section>

                <section className="mb-8">
                    <H3 className="uppercase mb-4">
                        3. CHANGED TERMS
                    </H3>
                    <P>
                        {`QuickComp shall have the right at any time to change or modify the terms and conditions applicable to User's use of the Platform or the Services (as herein defined), or any part thereof, or to impose new conditions, including, but not limited to, adding or amending fees and charges for use. Such changes, modifications, additions or deletions, whether affected by a change to the TOU or by other means shall be effective immediately upon notice thereof, which may be given by means including, but not limited to, posting on the Platform, or by electronic or conventional mail, or by any other means by which User obtains notice thereof. Any use of the Platform by User after such notice shall be deemed to constitute acceptance by User of such changes, modifications or additions.`}
                    </P>
                </section>

                <section className="mb-8">
                    <H3 className="uppercase mb-4">
                        4. DESCRIPTION OF SERVICES
                    </H3>
                    <P>
                        {`Through the Platform, QuickComp provides User with access to the Platform and materials produced therefrom in respect of information supplied by User, as well as download areas, communication forums, reviews, testimonials, and product information (collectively "Services"). The Services, including any updates, enhancements, new features, and/or the addition of any new Web properties, are subject to the TOU.`}
                    </P>
                </section>

                <section className="mb-8">
                    <H3 className="uppercase mb-4">
                        5. FEES AND PAYMENT
                    </H3>
                    <P>
                        {`In consideration of the provision of the Platform, QuickComp shall be entitled to collect a fee for service as listed on the Website. All Users shall provide valid credit card information required for payment processing at the time of subscription to the Platform. Fees shall be collected at the time User subscribes to the Platform, and on a monthly basis thereafter. QuickComp reserves the right to withhold access to the Platform in the event that User has failed to update their credit card information resulting in QuickComp’s inability to process any payment.`}
                    </P>
                    <P>
                        {`QuickComp reserves the right, but shall not be obligated to, upon notice or reasonable suspicion of any potential fraud, unauthorized charges or other misuse of the Website, place on hold any pending payment by or refund to the User.`}
                    </P>
                </section>

                <section className="mb-8">
                    <H3 className="uppercase mb-4">
                        6. EQUIPMENT
                    </H3>
                    <P>
                        {`User shall be responsible for obtaining and maintaining all computer hardware, software and other equipment needed for access to and use of the Platform and the Services and all charges related thereto. QuickComp is at liberty to make changes to the Platform or the Services from time to time, which changes may result in a change to the computer hardware, software and other equipment that User may require in order to access the Platform or utilize the Services.`}
                    </P>
                </section>

                <section className="mb-8">
                    <H3 className="uppercase mb-4">
                        7. USER CONDUCT
                    </H3>
                    <P className="pl-8">
                        {`A. User shall use the Platform for lawful purposes only. User shall not post or transmit through the Platform any material which violates or infringes in any way upon the rights of others, which is unlawful, threatening, abusive, defamatory, invasive of privacy or publicity rights, vulgar, obscene, profane or otherwise objectionable, which encourages conduct that would constitute a criminal offense, give rise to civil liability or otherwise violate any law, or which, without QuickComp’s express prior approval, contains advertising or any solicitation with respect to products or services. Any conduct by a User that in QuickComp's sole and absolute discretion offends any of these provisions or restricts or inhibits any other User from using or enjoying the Platform will not be permitted, and QuickComp may in its sole and absolute discretion either suspend or terminate the account of any offending User. Any User whose User Account has been so suspended or terminated shall be prohibited from creating a new User Account without the prior consent of QuickComp. User shall not use the Website to advertise or perform any commercial solicitation, including, but not limited to, the solicitation of users to become subscribers of other on-line information services competitive with QuickComp.`}
                    </P>
                    <P className="pl-8">
                        {`B. The Website contains proprietary material and information, including, but not limited to, text, software, photos, video, graphics, music and sound (the “Content”). All Content is and shall remain the intellectual property of QuickComp. QuickComp shall retain all right, title and interest to the selection, coordination, arrangement and enhancement of the Content. User may not modify, publish, transmit, participate in the transfer or sale, create derivative works, or in any way exploit, any of the Content, in whole or in part. User may download Content for User's use only. Except as otherwise expressly permitted under law, no copying, redistribution, retransmission, publication or commercial exploitation of Content or the Platform will be permitted without the express permission of QuickComp. User acknowledges that it does not acquire any ownership rights by downloading material proprietary to QuickComp. Notwithstanding the foregoing, QuickComp expressly disclaims any and all right, title and interest to any materials, data, or information uploaded by User, as more particularly described in Article 12 hereof.`}
                    </P>
                    <P className="pl-8">
                        {`C. User shall not upload, post or otherwise make available on the Platform any material protected by copyright, trademark or other proprietary right without the express permission of the owner of the copyright, trademark or other proprietary right and the burden of determining that any material is not protected by copyright rests with User. User shall be solely liable for any damage resulting from any infringement of copyrights, proprietary rights, or any other harm resulting from such a submission, and User shall indemnify and hold harmless QuickComp in respect of any claim, cost, damage, liability or loss resulting therefrom.`}
                    </P>
                    <P className="pl-8">
                        {`D. QuickComp reserves the right to restrict any User from registering an account on the Platform and may decline to provide the Platform to any User, if in the sole and unfettered discretion of QuickComp, the User poses a threat to the Platform or its operation or for any other reason in QuickComp’s sole and unfettered discretion.`}
                    </P>
                    <P className="pl-8">
                        {`E. The foregoing provisions of Section 7 are for the benefit of QuickComp and its third-party content providers and licensors and each shall have the right to assert and enforce such provisions directly or on its own behalf.`}
                    </P>
                </section>

                <section className="mb-8">
                    <H3 className="uppercase mb-4">
                        8. USE OF THE PLATFORM AND SERVICES
                    </H3>
                    <P>
                        {`The Services may include, without limitation, the Platform, email services, e-commerce services, data analysis, data storage, data interpretation, reports, documents, or profiles, photo albums, file cabinets and/or other message or communication facilities designed to enable User to communicate with others. User agrees to use the Services only to post, send and receive messages and material that are proper and, when applicable, related to the particular Services. By way of example, and not as a limitation, User agrees that when using the Services, User will not:`}
                    </P>
                    <P>
                        <ul className="list-disc pl-8 mt-2">
                            <li>{`Upload or transmit through the Platform or otherwise make available to QuickComp or any other person, files that contain data, images, photographs, software or other material protected by intellectual property laws, including without limitation, copyright or trademark laws or intellectual property legislation or by other rights of privacy or publicity, unless User owns or controls the rights or a licence thereto (which licence shall allow for the uploading of such material to the Website) or has received all necessary consent to do the same.`}</li>
                            <li>{`Use any material or information, including images or photographs which are made available through the Services in any manner that infringes any copyright, trademark, patent, trade secret, or other proprietary right of any party.`}</li>
                            <li>{`Upload files that contain viruses, Trojan horses, worms, time bombs, cancelbots, spyware, corrupted files, or any other similar software or programs that may damage the operation of the Website or another's computer or property of another.`}</li>
                            <li>{`Advertise or offer to sell or buy any goods or services for any business purpose.`}</li>
                            <li>{`Download any file available on the Platform that User knows, or reasonably should know, cannot be legally reproduced, displayed, performed, and/or distributed in such manner.`}</li>
                            <li>{`Falsify or delete any copyright management information, such as author attributions, legal or other proper notices or proprietary designations or labels of the origin or source of software or other material or date contained in a file that is uploaded.`}</li>
                            <li>{`Violate any code of conduct or other guidelines which may be applicable for any particular Service, including without limitation the Website Privacy Policy published by QuickComp.`}</li>
                            <li>{`Harvest or otherwise collect information from the Website other than material delivered to User by QuickComp in connection with the Services. `}</li>
                            <li>{`Violate any applicable laws or regulations.`}</li>
                            <li>{`Create a false identity for the purpose of misleading others.`}</li>
                        </ul>
                    </P>
                    <P>
                        {`QuickComp has no obligation to monitor the User’s use of the Services. However, QuickComp reserves the right to review materials posted to the Services and to remove any materials in its sole discretion. QuickComp reserves the right to terminate a User’s access to any or all of the Services at any time, without notice, for any reason whatsoever. QuickComp reserves the right at all times to disclose any information as it deems necessary to satisfy any applicable law, regulation, legal process or governmental request, or to edit, refuse to post or to remove any information or materials, in whole or in part, in QuickComp’s sole discretion.`}
                    </P>
                    <P>
                        {`Materials uploaded to the Platform or otherwise provided to you by QuickComp, including without limitation the Platform and materials produced therefrom, may be subject to posted limitations on usage, reproduction and/or dissemination; User is responsible for adhering to such limitations if User downloads the materials.`}
                    </P>
                    <P>
                        {`Always use caution and give care and attention to the accuracy of information when providing or uploading information or data (including personally identifiable information) through the Platform. QuickComp does not control or endorse the content, messages or information uploaded to the Platform and, therefore, QuickComp specifically disclaims any liability with regard to content of the Platform any actions resulting from User’s use of the Platform.`}
                    </P>

                </section>
                <section className="mb-8">
                    <H3 className="uppercase mb-4">
                        9. MEMBER ACCOUNT, PASSWORD, AND SECURITY
                    </H3>
                    <P>
                        {`If any of the Services requires User to open a User’s Account, User must complete the registration process by providing QuickComp with current, complete and accurate information as prompted by the applicable registration form. User also will choose a password and a user name. User is entirely responsible for maintaining the confidentiality of User’s password and User’s Account. Furthermore, User is entirely responsible for any and all activities that occur under User’s Account. User agrees to notify QuickComp immediately of any unauthorized use of User’s Account or any other breach of security or the Platform Privacy Policy. QuickComp will not be liable for any loss that User may incur as a result of someone else using User’s password or User’s Account, either with or without User’s knowledge. However, User could be held liable for losses incurred by QuickComp or another party due to someone else using User’s Account or password. User may not use or control a User’s Account registered to any other person at any time, without the permission of the account holder and QuickComp.`}
                    </P>
                </section>
                <section className="mb-8">
                    <H3 className="uppercase mb-4">
                        10. NOTICE SPECIFIC TO DOCUMENTS AVAILABLE ON THE WEBSITE
                    </H3>
                    <P>
                        {`Permission to use documents including press releases, datasheets, and FAQs from the Platform (collectively, the “Documents”) is granted, provided that use of the Documents is for informational and non-commercial or personal use only.`}
                    </P>
                </section>
                <section className="mb-8">
                    <H3 className="uppercase mb-4">
                        11. NOTICES REGARDING SOFTWARE, DOCUMENTS AND SERVICES AVAILABLE ON THIS WEBSITE
                    </H3>
                    <P>
                        {`In no event shall QuickComp and/or its respective suppliers be liable for any direct, special, indirect or consequential damages or any other damages whatsoever resulting from loss of use, data or profits, whether in an action of contract, negligence or other tortious action, arising out of or in connection with your use of or the performance of the Platform, the provision of the Platform to you, your use of or reliance upon the Documents, or our provision of or failure to provide any Services.`}
                    </P>
                </section>
                <section className="mb-8">
                    <H3 className="uppercase mb-4">
                        12. NOTICES REGARDING SOFTWARE, DOCUMENTS AND SERVICES AVAILABLE ON THIS WEBSITE
                    </H3>
                    <P>
                        {`QuickComp does not claim ownership of the materials, data, or information User provides to QuickComp (including without limitation material provided in the form of data, information, photographs, reviews, testimonials, feedback and suggestions) or that User may post, upload, input or submit to the Platform, (each a "Submission" and collectively "Submissions"). However, by posting, uploading, inputting, providing or submitting any Submission, User is granting QuickComp, its affiliated companies and necessary sublicensees permission to use such Submission in connection with the operation of their regular day-to-day activities (including, without limitation, all QuickComp Services), including, without limitation, the license rights to:`}
                        <ul className="list-disc pl-8 mt-2">
                            <li>{`In respect of Submissions made by User to publicly accessed areas of the Platform only, distribute, transmit, publicly display, publicly perform, reproduce, edit, translate and reformat any Submission; to publish User’s name in connection with any such Submission; and`}</li>
                            <li>{`In respect of Submissions made by User in connection with use of the Platform, to store, manipulate, reproduce and interpret the Submissions for the limited purpose of creating the Platform and documents related thereto.`}</li>
                        </ul>
                    </P>
                    <P>
                        {`No compensation will be paid with respect to the use of any Submission as provided herein. QuickComp is under no obligation to use any Submission User may provide and QuickComp may remove any Submission at any time in its sole discretion. By posting a Submission to a publicly-accessed area of the Website, User warrants and represents to own or otherwise control rights to such Submission necessary for User’s posting of the Submission.`}
                    </P>
                    <P className="font-semibold">
                        {`The accuracy and completeness of the Platform and materials produced therefrom depends largely on the accuracy of your Submissions. It is your responsibility to ensure that the Submissions are true and accurate.`}
                    </P>
                </section>
                <section className="mb-8">
                    <H3 className="uppercase mb-4">
                        13. NOTICES REGARDING SOFTWARE, DOCUMENTS AND SERVICES AVAILABLE ON THIS WEBSITE
                    </H3>
                    <P>
                        {`A.  User expressly agrees that use of the Platform is at User's sole risk. None of QuickComp, its affiliates nor any of their respective directors, officers, employees, agents, third party content providers or licensors warrant that the Platform will be uninterrupted or error free; nor do they make any warranty as to the results that may be obtained from use of the Platform or as to the accuracy, reliability or content of any information, service, or merchandise provided through the Platform (including without limitation the Platform).`}
                    </P>
                    <P>
                        {`B.  The Website and the Platform are provided on an "as is" basis without warranties of any kind, either express or implied, including, but not limited to, warranties of title or implied warranties of merchantability or fitness for a particular purpose, other than those warranties which are implied by law and incapable of exclusion, restriction or modification under the laws applicable to the TOU.`}
                    </P>
                    <P>
                        {`C.  The information, content, software, products, and services published on the Website or delivered in the form of the Platform may include inaccuracies or errors, whether arising from information provided by User or by QuickComp. QuickComp does not guarantee the accuracy of, and disclaims all liability for, any errors or other inaccuracies relating to the Services or the Website (including, without limitation, the Platform, information relating to pricing, availability, features, inclusions and exclusions, general product descriptions, reviews, testimonials and ratings, etc.).`}
                    </P>
                    <P>
                        {`D.  This disclaimer of liability applies to any damages or injury caused by any failure of performance, error, omission, interruption, deletion, defect, delay in operation or transmission, computer virus, communication line failure, theft or destruction or unauthorized access to, alteration of, or use of records, information or data, whether for breach of contract, tortious behavior, negligence, or under any other cause of action.`}
                    </P>
                    <P>
                        {`E.  In no event will QuickComp, or any person or entity involved in creating, producing or distributing the Website or the Platform be liable for any damages, including, without limitation, direct, indirect, incidental, special, consequential, or punitive damages arising out of the use of or inability to use the Website or the Platform. User hereby acknowledges that the provisions of this section shall apply to all content on the Website and the Platform and any materials produced therefrom.`}
                    </P>
                    <P>
                        {`F.  Third-party suppliers are independent contractors and not agents or employees of QuickComp. QuickComp is not liable for the acts, errors, omissions, representations, warranties, breaches, negligence or misconduct of any third party supplier or for any losses, damages, claims or expenses resulting therefrom or otherwise arising from User’s use of the Website or Services or reliance on the Platform.`}
                    </P>
                    <P>
                        {`G.  In addition to the terms set forth above none of QuickComp, its information providers or content partners shall be liable regardless of the cause or duration, for any errors, inaccuracies, omissions, or other defects in, or untimeliness or unauthenticity of, the information contained within the Website, or for any delay or interruption in the transmission thereof to the user, or for any claims or losses arising therefrom or occasioned thereby. None of the foregoing parties shall be liable for any third-party claims or losses of any nature, including, but not limited to, lost profits, punitive or consequential damages.`}
                    </P>
                    <P>
                        {`H.  QuickComp shall not be responsible for any failure or delay in performance of the Services due to circumstances beyond its reasonable control, including, without limitation, acts of god, war, riot, embargoes, acts of civil or military authorities, fire, floods, accidents, pandemics, epidemics, service outages resulting from equipment and/or software failure and/or telecommunications failures, power failures, network failures, failures of third party service providers (including providers of internet services and telecommunications) (collectively, “Force Majeure”). Any obligation by QuickComp to perform the Services or to operate the Website shall be suspended for as long as any event of Force Majeure shall prevent the QuickComp from performing the same.`}
                    </P>
                </section>
                <section className="mb-8">
                    <H3 className="uppercase mb-4">
                        14. LINKS TO THIRD PARTY SITES
                    </H3>
                    <P>
                        {`QuickComp may from time to time provide links on the Platform to other websites not under the control of QuickComp. QuickComp is not responsible for the contents of any linked site or any link contained in a linked site, or any changes or updates to such sites. QuickComp is not responsible for webcasting or any other form of transmission received from any linked site. QuickComp provides these links only as a convenience, and the inclusion of any link does not imply endorsement by QuickComp of the site.`}
                    </P>
                </section>
                <section className="mb-8">
                    <H3 className="uppercase mb-4">
                        15. UNSOLICITED IDEA SUBMISSION POLICY
                    </H3>
                    <P>
                        {`Neither QuickComp nor any of its directors, officers or employees will accept or consider unsolicited ideas, including ideas for new advertising campaigns, new promotions, new products or technologies, processes, materials, marketing plans or new product names. Please do not submit any original creative artwork, samples, demos, or other works to QuickComp. The sole purpose of this policy is to avoid potential misunderstandings or disputes when QuickComp’s products or marketing strategies might seem similar to ideas submitted to QuickComp. Please do not submit unsolicited ideas to QuickComp or any employee of QuickComp. If, despite our request that no ideas or materials be submitted to QuickComp, such submission is made, QuickComp makes no assurances that submitted ideas and materials will be treated as confidential or proprietary.`}
                    </P>
                </section>
                <section className="mb-8">
                    <H3 className="uppercase mb-4">
                        16. MONITORING
                    </H3>
                    <P>
                        {`QuickComp shall have the right, but not the obligation, to monitor the content of the Platform, including Submissions, to determine compliance with the TOU and any operating rules established by QuickComp (whether specifically referred to herein or not) and to satisfy any law, regulation or authorized government request. QuickComp shall have the right in its sole discretion to edit, refuse to post or remove any material submitted to or posted on the Website. Without limiting the foregoing, QuickComp shall have the right to remove any material that QuickComp, in its sole discretion, finds to be in violation of the provisions hereof or otherwise objectionable.`}
                    </P>
                </section>
                <section className="mb-8">
                    <H3 className="uppercase mb-4">
                        17. INDEMNIFICATION
                    </H3>
                    <P>
                        {`User agrees to defend, indemnify and hold harmless QuickComp, its affiliates and their respective directors, officers, employees and agents from and against any claims, causes of action, demands, suits, proceedings, investigations, losses, damages, fines, penalties, fees, expenses, costs and any other liabilities of any kind or nature including but not limited to reasonable legal and accounting fees, arising out of or related to:`}
                        <ol className="list-decimal pl-8 mt-2">
                            <li>{`Use of the Platform by User or their User Account, whether or not under the control of User;`}</li>
                            <li>{`User’s breach of the TOU or the documents referenced herein;`}</li>
                            <li>{`User’s violation of any law, rule, regulation or guideline;`}</li>
                            <li>{`User’s violation, infringement or misappropriation of the rights of QuickComp or any third party, including without limitation any rights of publicity or privacy;`}</li>
                            <li>{`User’s use of the Platform or  of products or Services obtained through the Website; or`}</li>
                            <li>{`User’s negligence or willful misconduct.`}</li>
                        </ol>
                    </P>
                </section>
                <section className="mb-8">
                    <H3 className="uppercase mb-4">
                        18. TERMINATION
                    </H3>
                    <P>
                        {`QuickComp shall have the right to immediately terminate User's Account in the event of any conduct by User which QuickComp, in its sole discretion, considers to be unacceptable, or in the event of any breach by User of the TOU or any operating rules that QuickComp may develop from time to time, whether referred to herein or not.`}
                    </P>
                </section>
                <section className="mb-8">
                    <H3 className="uppercase mb-4">
                        19. MISCELLANEOUS
                    </H3>
                    <P>
                        {`The TOU, the Privacy Policy, and any operating rules for the Platform established by QuickComp constitute the entire agreement of the parties with respect to the subject matter hereof, and supersede all previous written or oral agreements between the parties with respect to such subject matter. The TOU shall be construed in accordance with the laws of the Province of Alberta, Canada, without regard to its conflict of laws rules. No waiver by either party of any breach or default hereunder shall be deemed to be a waiver of any preceding or subsequent breach or default. The section headings used herein are for convenience only and shall not be given any legal import.  The Parties also attorn to the jurisdiction of the Courts of the Province of Alberta for the determination of any dispute arising in connection with the TOU or the User’s use of the Platform.`}
                    </P>
                    <P>
                        {`Any rights not expressly granted herein are reserved.`}
                    </P>
                </section>
            </div>
        </Layout>
    )
}
